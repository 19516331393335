import { observer } from "mobx-react";
import { modalStore } from "MobxFarm/store";
import ModalPortal from "ComponentsFarm/element/Portal";
import PrepStatus from "ComponentsFarm/layer/PrepStatus";
import PrepManagement from "ComponentsFarm/layer/PrepManagement";

export const LAYER_TYPES = {
  SummeryLayer: "SummeryLayer",
  PrepStatus: "PrepStatus",
  PrepManagement: "PrepManagement",
  HistoryLayer: "HistoryLayer",
  SettingLayer: "SettingLayer",
} as const;

export const MODAL_TYPES = {
  ReceiptHandler: "ReceiptHandler",
  AddOrderHandler: "AddOrderHandler",
} as const;

const LAYER_COMPONENTS: any = {
  [LAYER_TYPES.PrepStatus]: PrepStatus,
  [LAYER_TYPES.PrepManagement]: PrepManagement,
};

const LayoutArea = ({
  areaNumber,
  soundEffectPlay,
}: {
  areaNumber: number;
  soundEffectPlay: (src: string) => void;
}) => {
  const renderComponent = () => {
    if (!modalStore.modalName && !modalStore.layerName) {
      return null;
    }

    const LayerComponent = LAYER_COMPONENTS[modalStore.layerName];

    if (!modalStore.modalName && modalStore.layerName) {
      return (
        <LayerComponent
          areaNumber={areaNumber}
          soundEffectPlay={soundEffectPlay}
          {...modalStore.layerProps}
        />
      );
    } else if (modalStore.modalName && modalStore.layerName) {
      return (
        <div>
          <LayerComponent {...modalStore.layerProps} />
        </div>
      );
    }
  };

  return <ModalPortal>{renderComponent()}</ModalPortal>;
};

export default observer(LayoutArea);
