import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { Dimm, Header } from "./styles/common";
import { Swiper } from "swiper/types";
import { menu } from "PagesFarm/Home";
import { observer } from "mobx-react";
import useSelectModal from "HookFarm/useSelectModal";
import ModalPortal from "./element/Portal";
import LogoutPopup from "./popup/LogoutPopup";

// const status = [{id:1, txt: '완료 대기 중', status:"finWait"} , '처리 중', '처리 대기 중'];
const status = [
  { id: 1, txt: "처리", status: "ing" },
  { id: 2, txt: "대기", status: "wait" },
];

interface IinfoStatus {
  areaNumber: number;
  setAreaNumber: Dispatch<SetStateAction<number>>;
  infoStatus: {
    [key: string]: string | number;
    ing: number;
    wait: number;
  };
  totalCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

function KdsHeader({
  areaNumber,
  setAreaNumber,
  infoStatus,
  totalCount,
  currentPage,
  setCurrentPage,
}: IinfoStatus) {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const { showLayer, setShowLayer, hideModal, handleSelectLayer } =
    useSelectModal();

  const soundEffectPlay = useCallback((src: string) => {
    const audio = new Audio(src);
    audio.play();
  }, []);

  const handlerMenu = useCallback(
    (selectLayer: string) => {
      soundEffectPlay("/sound/btn_menu.mp3");
      if (selectLayer === "filterLayer") {
        hideModal();
      }
      handleSelectLayer(selectLayer);
    },
    [soundEffectPlay, hideModal, handleSelectLayer]
  );

  const handlerMenuMove = (idx: number) => {
    setAreaNumber(idx);
    soundEffectPlay("/sound/btn_menu.mp3");
  };

  return (
    <Header>
      <div className="left">
        <span className="box_page_info">
          <button
            className="btn_page btn_prev"
            onClick={() => setCurrentPage((prev) => prev - 1)}
            disabled={currentPage === 1}
          >
            <span className="hiddenZoneV">이전</span>
          </button>
          <div className="wrap_page_number">
            <span className="current">{currentPage}</span>
            <span className="bar">/</span>
            <span className="total">{totalCount === 0 ? 1 : totalCount}</span>
          </div>
          <button
            className="btn_page btn_next"
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={currentPage >= totalCount}
          >
            <span className="hiddenZoneV">다음</span>
          </button>
        </span>
        <ul className="status">
          {status.map((el) => (
            <li key={el.id}>
              <span className="box_current_status">
                <span className="txt">{el.txt}</span>
                <span className="num">{infoStatus[el.status]}</span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="right">
        <ul className="menu">
          {menu.map((el, i) => (
            <li
              key={el.idx}
              className={`${areaNumber === el.idx ? "on" : ""}`}
              onClick={() => handlerMenuMove(el.idx)}
            >
              {el.areaName}
            </li>
          ))}
        </ul>
        <div className={`area_btn ${showLayer === "PrepStatus" ? "on" : ""}`}>
          <button
            onClick={() => {
              handlerMenu("PrepStatus");
            }}
          >
            프랩관리
          </button>
        </div>
        <div className={`area_btn`} style={{ marginLeft: "30px" }}>
          <button onClick={() => setShowLogoutPopup(true)}>설정</button>
        </div>
      </div>

      {showLayer !== "" && (
        <Dimm
          style={{ top: "5.2rem" }}
          onClick={() => {
            setShowLayer("");
            handlerMenu("");
          }}
        />
      )}

      {showLogoutPopup && (
        <ModalPortal>
          <LogoutPopup onClose={() => setShowLogoutPopup(false)} />
        </ModalPortal>
      )}
    </Header>
  );
}

export default observer(KdsHeader);
