import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IPrepAddReq,
  IPrepProcessItem,
  IPrepProcessReq,
  IPrepSubListRes,
} from "ApiFarm/interface/prep";
import { fetchPrepAdd, fetchPrepProcess, fetchPrepSubList } from "ApiFarm/prep";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import useSelectModal from "HookFarm/useSelectModal";
import { prepListStore, prepStore } from "MobxFarm/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import LayerSettingHead from "../LayerSettingHead";
import ModalPortal from "../element/Portal";
import LayerConfirm from "../popup/LayerConfirm";
import { PrepManagementWrap } from "../styles/common";
import { ISubkdsListRes } from "ApiFarm/interface/subkds";

function PrepManagement({
  areaNumber,
  soundEffectPlay,
}: {
  areaNumber: number;
  soundEffectPlay: (src: string) => void;
}) {
  const queryClient = useQueryClient();

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  const { showLayer, handleSelectLayer } = useSelectModal();

  const handlerBackBtn = () => {
    handleSelectLayer("PrepStatus");
  };

  //선택한 제품들의 Prep 리스트
  const { data: prepManagement, refetch } = useQuery<
    IPrepSubListRes,
    AxiosError
  >(["prepManagement", prepStore.product_idx], () =>
    fetchPrepSubList(prepStore.product_idx)
  );

  const [usePrep, setUsePrep] = useState<IPrepProcessItem[]>([]);

  //추가
  const addPrep = useMutation(["addPrep"], fetchPrepAdd, {
    onMutate: async (newPrep) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        "prepManagement",
        prepStore.product_idx,
      ]);
      await queryClient.cancelQueries(["subKdsListData", areaNumber]);

      // Snapshot the previous value
      const previousPrepData = queryClient.getQueryData<IPrepSubListRes>([
        "prepManagement",
        prepStore.product_idx,
      ]);
      const previousSubKdsData = queryClient.getQueryData<ISubkdsListRes>([
        "subKdsListData",
        areaNumber,
      ]);

      // Optimistically update prep management data
      if (previousPrepData) {
        queryClient.setQueryData<IPrepSubListRes>(
          ["prepManagement", prepStore.product_idx],
          (old) => ({
            ...old!,
            info: {
              ...old!.info,
              count: old!.info.count + 1,
            },
            list: [
              {
                prep_management_idx: Date.now(), // Temporary ID
                prep_name: newPrep.prep_name,
                created_date: new Date().toISOString(),
                use_status: 0,
              },
              ...old!.list,
            ],
          })
        );
      }

      // Optimistically update subKds data
      if (previousSubKdsData) {
        queryClient.setQueryData<ISubkdsListRes>(
          ["subKdsListData", areaNumber],
          (old) => ({
            ...old!,
            list: old!.list.map((item) =>
              item.product_name === newPrep.prep_name
                ? { ...item, is_prep: 1 }
                : item
            ),
          })
        );
      }

      prepListStore.addPrep(newPrep.prep_name);
      return { previousPrepData, previousSubKdsData };
    },
    onError: (err, newPrep, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousPrepData) {
        queryClient.setQueryData(
          ["prepManagement", prepStore.product_idx],
          context.previousPrepData
        );
      }
      if (context?.previousSubKdsData) {
        queryClient.setQueryData(
          ["subKdsListData", areaNumber],
          context.previousSubKdsData
        );
      }
      prepListStore.removePrep(newPrep.prep_name);
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries(["prepManagement", prepStore.product_idx]);
      queryClient.invalidateQueries(["subKdsListData", areaNumber]);
    },
  });

  console.log("addPrep", addPrep.data);

  const handlerAddPrep = () => {
    soundEffectPlay("/sound/btn_menu.mp3");
    addPrep.mutate(
      {
        ...prepStore,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["prepManagement"]);
        },
      }
    );
  };

  //사용, 폐지
  const processPrep = useMutation(
    ["processPrep"],
    (request: IPrepProcessReq) => fetchPrepProcess(request),
    {
      onMutate: async (newPrep) => {
        await queryClient.cancelQueries([
          "prepManagement",
          prepStore.product_idx,
        ]);
        await queryClient.cancelQueries(["subKdsListData", areaNumber]);

        const previousPrepData = queryClient.getQueryData<IPrepSubListRes>([
          "prepManagement",
          prepStore.product_idx,
        ]);
        const previousSubKdsData = queryClient.getQueryData<ISubkdsListRes>([
          "subKdsListData",
          areaNumber,
        ]);

        if (previousPrepData) {
          queryClient.setQueryData<IPrepSubListRes>(
            ["prepManagement", prepStore.product_idx],
            (old) => {
              if (!old) return old;
              const updatedList = old.list.map((item) =>
                newPrep.list.some(
                  (newItem) =>
                    newItem.prep_management_idx === item.prep_management_idx
                )
                  ? { ...item, use_status: newPrep.list[0].use_status }
                  : item
              );
              const availablePrepCount = updatedList.filter(
                (item) => item.use_status === 0
              ).length;

              if (previousSubKdsData) {
                queryClient.setQueryData<ISubkdsListRes>(
                  ["subKdsListData", areaNumber],
                  (oldSubKds) => {
                    if (!oldSubKds) return oldSubKds;
                    return {
                      ...oldSubKds,
                      list: oldSubKds.list.map((item) =>
                        item.product_name === prepStore.prep_name
                          ? { ...item, is_prep: availablePrepCount > 0 ? 1 : 0 }
                          : item
                      ),
                    };
                  }
                );
              }

              prepListStore.removePrep(prepStore.prep_name);

              return {
                ...old,
                info: { ...old.info, count: availablePrepCount },
                list: updatedList,
              };
            }
          );
        }

        return { previousPrepData, previousSubKdsData };
      },
      onError: (err, newPrep, context) => {
        if (context?.previousPrepData) {
          queryClient.setQueryData(
            ["prepManagement", prepStore.product_idx],
            context.previousPrepData
          );
        }
        if (context?.previousSubKdsData) {
          queryClient.setQueryData(
            ["subKdsListData", areaNumber],
            context.previousSubKdsData
          );
        }
        prepListStore.addPrep(prepStore.prep_name);
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "prepManagement",
          prepStore.product_idx,
        ]);
        queryClient.invalidateQueries(["subKdsListData", areaNumber]);
      },
    }
  );
  //사용 할 아이템들 체킹
  const hanlerUsePrep = (type: string, prep_management_idx: number) => {
    type === "use"
      ? setUsePrep([...usePrep, { prep_management_idx, use_status: 1 }])
      : setUsePrep(
          usePrep.filter((el) => el.prep_management_idx !== prep_management_idx)
        );
  };

  //사용하는지에 대한 확인 팝업창

  //폐지
  const handlerRemovePrep = useCallback(
    (prep_management_idx: number) => {
      processPrep.mutate(
        {
          list: [{ prep_management_idx, use_status: 9 }],
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(["prepManagement"]);
            queryClient.invalidateQueries(["subKdsListData"]);
          },
          onError: (err) => {
            console.error(err);
          },
        }
      );
    },
    [processPrep, queryClient]
  );

  //프립 적용
  const handlerConfirmDone = useCallback(() => {
    processPrep.mutate(
      {
        list: [...usePrep],
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(["prepManagement"]);
          setUsePrep([]);
          setShowConfirmLayer(false);
        },
      }
    );
  }, [processPrep, queryClient, usePrep]);

  const availablePrepLength = useMemo(
    () => prepManagement?.list.filter((el) => el.use_status === 0).length,
    [prepManagement?.list]
  );

  return (
    <>
      <PrepManagementWrap className="layer_setting">
        <div className="head">
          <button
            className="btn_back"
            onClick={() => {
              handlerBackBtn();
              soundEffectPlay("/sound/btn_menu.mp3");
            }}
          >
            <span className="hiddenZone">뒤로</span>
          </button>

          <h2>{prepManagement?.info.product_name}</h2>
        </div>
        {/* <div className="status">
          <span className="item_name">{prepManagement?.info.product_name}</span>
          <span className="number">
            총 {prepManagement?.info.count}개{" "}
            {usePrep.length !== 0 && `중 ${usePrep.length}개 사용`}
          </span>
          {usePrep.length !== 0 && (
            <button
              className="add_confirm on"
              onClick={() => {
                soundEffectPlay("/sound/btn_menu.mp3");
                setShowConfirmLayer(true);
              }}
            >
              <span className="txt">적용</span>
            </button>
          )}
        </div> */}
        <div className="control">
          <span className="title">
            현재 제조된 수량 : {availablePrepLength}개
          </span>
          <span className="btn_area">
            <button className="add_btn" onClick={handlerAddPrep}>
              <span className="txt">추가</span>
            </button>
          </span>
        </div>
        <ul className="list_prep">
          {prepManagement?.list.map(
            (el) =>
              el.use_status === 0 && (
                <li key={el.prep_management_idx}>
                  <span>
                    {dayjs(el.created_date).format(`YY-MM-DD  HH:mm`)} 제작
                  </span>
                  <span className="button_area">
                    {/* <button
                      className={`btn_use ${
                        usePrep.some(
                          (item) =>
                            item.prep_management_idx === el.prep_management_idx
                        )
                          ? "on"
                          : ""
                      }`}
                      onClick={() => {
                        soundEffectPlay("/sound/btn_menu.mp3");
                        usePrep.every(
                          (item) =>
                            item.prep_management_idx !== el.prep_management_idx
                        )
                          ? hanlerUsePrep("use", el.prep_management_idx)
                          : hanlerUsePrep("unUse", el.prep_management_idx);
                      }}
                    >
                      사용
                    </button> */}
                    <button
                      className="btn_trash"
                      disabled={usePrep.some(
                        (item) =>
                          item.prep_management_idx === el.prep_management_idx
                      )}
                      onClick={() => {
                        soundEffectPlay("/sound/btn_menu.mp3");
                        handlerRemovePrep(el.prep_management_idx);
                      }}
                    >
                      폐지
                    </button>
                  </span>
                </li>
              )
          )}
        </ul>
      </PrepManagementWrap>
      {showConfirmLayer && (
        <ModalPortal>
          <LayerConfirm
            title={"Prep 사용확인"}
            text={"사용체크한 Prep을 사용하시겠습니까?"}
            cancle={() => {
              soundEffectPlay("/sound/btn_menu.mp3");
              setShowConfirmLayer(false);
            }}
            confirm={() => {
              soundEffectPlay("/sound/btn_menu.mp3");
              handlerConfirmDone();
            }}
          />
        </ModalPortal>
      )}
    </>
  );
}

export default PrepManagement;
